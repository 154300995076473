import { fetchList as fetchMenu } from '@/api/setting/menu'
import publicRoutes from '@/router/public-routes'
export default {

    namespaced: true,

    state() {
        return {
            user: {}, // 用户基本信息
            menuList: [], // 用户菜单和按钮的原始数组数据
            menu: [], // 用户菜单(不包括按钮)的树形数据，用于左侧菜单的显示
        }
    },

    getters: {

        // 当前用户是否是生产管理
        isProduction(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "product"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 当前用户是否是院长
        isPresident(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "product_design_institute"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 当前用户是否是合同管理员
        isContractAdmin(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "product_contractManager"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 当前用户是否是会议管理员
        isMeetingAdmin(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "office_meeting_manager"
                );
                return index > -1
            } else {
                return false;
            }

        },

        // 是否是论坛的公告发布管理员
        isAnnounceManager(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "announce_manager"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 是否是招聘管理员
        isRecruitmentManager(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "recruitment_manager"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 是否是epc管理员
        isEpcManager(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "epc_admin"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 是否是评标管理员
        isBidManager(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "bid_admin"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 是否是评标评委
        isBidCommittee(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "bid_jury"
                );
                return index > -1
            } else {
                return false;
            }
        },

        // 当前用户是否是举报管理员
        isReportManager(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "QS_manager"
                );
                return index > -1
            } else {
                return false;
            }
        },
        // 当前用户是否是人力资源
        isHr(state) {
            if (Array.isArray(state.user.roles)) {
                const index = state.user.roles.findIndex(
                    (item) => item.code === "hr"
                );
                return index > -1
            } else {
                return false;
            }

        },
        // 不包含按钮的菜单数组
        pureMenu(state) {
            return state.menuList.filter(item => item.type !== 'button')
        },

        getCurrentRoute: state => path => {
            return state.menuList.find(item => item.path === path)
        },

        // 当前用户的部门
        department(state) {
            return state.user.employeeVo ? state.user.employeeVo : {}
        }
    },

    mutations: {

        setUser(state, payload) {
            console.log(payload)

            window.localStorage.setItem("user", JSON.stringify(payload));
            state.user = payload
        },
        setMenuList(state, payload) {
            state.menuList = payload
        },
        setMenu(state, payload) {
            state.menu = payload;
        }
    },

    actions: {
        getMenu({ commit }, payload) {

            return new Promise((resolve, reject) => {

                console.log('get menu tree payload ', payload)
                fetchMenu({
                    platform: 'pc'
                }).then((res) => {
                    if (Array.isArray(res)) {

                        // 给角色绑定权限列表，不绑定菜单列表，在当前角色下注册了用户后，也就标明了该用户有了权限表，登陆后拿着所有的菜单表去跟权限交叉匹配。
                        const resultList = []

                        res.forEach(item => {
                            const result = payload.find(element => element.code === item.permission)
                            if (result) {
                                resultList.push(item)
                            }
                        })

                        const list = resultList.filter(item => item.status === 1) // 这里的逻辑应该是过滤出正常的菜单
                        list.push(...publicRoutes)

                        // 菜单原始数据
                        window.localStorage.setItem('menuList', JSON.stringify(list))
                        commit("setMenuList", list)

                        const noButton = list.filter(item => item.type !== 'button')
                        // 左侧菜单
                        let tree = Object.freeze(buildTree(noButton, "0"));
                        tree = tree.filter((v)=>v.name !== '党群建设');
                        window.localStorage.setItem('menu', JSON.stringify(tree))
                        commit('setMenu', tree)

                        resolve();
                    }
                }).catch(() => {
                    reject();
                });

            })


        }
    }

}


// 将后端返回的菜单数据过滤成数据结构，构建系统左侧嵌套菜单
function buildTree(list, parentUuid) {

    let tree = []

    list.forEach(item => {
        if (item.parentUuid === parentUuid) {


            const result = {
                ...item,
            }

            const children = buildTree(list, item.uuid)

            if (children.length > 0) {
                result.children = children;
            }

            tree.push(result)
        }
    })

    tree.sort((x, y) => {
        if (x.sort < y.sort) {
            return -1
        } else if (x.sort > y.sort) {
            return 1
        } else {
            return 0
        }
    })

    return tree;
}